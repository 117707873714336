import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ComponentMap, richContentOptions } from "components";
import FinanceForm from "components/finance/form";
import { useState } from "react";

function com(a, b) {
  const date1 = new Date(a.sys.updatedAt).getTime();
  const date2 = new Date(b.sys.updatedAt).getTime();
  return date1 - date2;
}

export default function Columns({
  columns = 2,
  fields,
  sys,
  includes,
  plain = false,
  addItem = null,
  removeItem = null,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const content = fields.intro
    ? documentToReactComponents(fields.intro, richContentOptions)
    : null;

  const isFinance =
    rest &&
    rest.primary &&
    rest.primary.fields &&
    rest.primary.fields.url === "/finance";

  const containerCls = plain
    ? "relative pb-12"
    : "relative bg-gray-50 pb-20 px-4 sm:px-6 lg:pt-0 lg:pb-28";


  return (
    <div className={containerCls}>
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        {!plain && (
          <div className="text-center">
            {fields.title && (
              <h2 className="mb-12 text-3xl tracking-tight font-sans font-semibold text-gray-900 sm:text-4xl">
                {fields.title}
              </h2>
            )}
            {content}
          </div>
        )}
        <div
          className={`${
            fields.title || fields.intro ? "md:mt-12" : ""
          } relative mx-auto grid gap-12 md:grid-cols-${
            columns - 1
          } lg:grid-cols-${columns} lg:max-w-none`}
        >
          {fields.items &&
            fields.items.map((item, key) => {
              const Cmp = ComponentMap[item.sys.contentType.sys.id];
              if (Cmp) {
                if (isFinance) {
                  return (
                    <button
                      key={`${key}-${item.sys.contentType.sys.id}`}
                      type="button"
                      className="m-0 p-0 border-none"
                      onClick={() => setOpen(true)}
                    >
                      <Cmp
                        fields={item.fields}
                        sys={item.sys}
                        includes={includes}
                        addItem={addItem}
                        removeItem={removeItem}
                      />
                    </button>
                  );
                }
                return (
                  <Cmp
                    key={`${key}-${item.sys.contentType.sys.id}`}
                    fields={item.fields}
                    sys={item.sys}
                    includes={includes}
                    addItem={addItem}
                    removeItem={removeItem}
                  />
                );
              }

              return (
                <ComponentMap.card
                  key={`${key}-${item.sys.contentType.sys.id}`}
                  fields={item.fields}
                  options={fields.options}
                  sys={item.sys}
                  includes={includes}
                />
              );
            })}
        </div>
      </div>
      {isFinance && open ? <FinanceForm open={open} setOpen={setOpen} /> : null}
    </div>
  );
}
