import { useEffect, useState, useRef, ReactElement } from "react";
import { createPortal } from "react-dom";

function ClientOnlyPortal({ children, selector }) {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted ? createPortal(children, ref.current) : null;
}

export interface Modal {
  open: boolean;
  close: () => void;
  children?: any;
  large?: boolean;
  xlarge?: boolean;
  xxlarge?: boolean;
  full?: boolean;
  title?: string | ReactElement;
  subTitle?: any;
  bg?: string;
}

const zIndex = { zIndex: 9999 };

export default function Modal({
  open,
  close,
  children,
  large = false,
  xlarge = false,
  xxlarge = false,
  full = false,
  title,
  subTitle,
  bg,
}: Modal) {
  const [state, setState] = useState({
    displayed: false,
  });

  // useEffect(() => {
  //   if (open && !state.displayed) {
  //     document.body.style.overflow = "hidden";
  //     setState({ ...state, displayed: true });
  //   } else if (!open && state.displayed) {
  //     document.body.style.overflow = "auto";
  //     setState({ ...state, displayed: false });
  //   }
  // }, [open]);
console.log('close', close)
  useEffect(() => {
    const h = (e) => {
      if (e.key === "Escape") close();
    }
    document.addEventListener("keydown", h);
    return () => document.removeEventListener("keydown", h);
  }, [])

  // Not sure why the above isn't working so this will do
  useEffect(() => {
    if (open && !state.displayed) {
      document.body.style.overflow = "auto";
      setState({ ...state, displayed: true });
    }
  }, [open]);

  const cls = () => {
    if (large) return "sm:max-w-xl sm:w-full sm:my-8";
    if (xlarge) return "sm:max-w-2xl sm:w-full sm:my-8";
    if (xxlarge) return "sm:max-w-3xl sm:w-full space-y-4 sm:my-8";
    if (full) return "mt-6 w-full h-screen space-y-4";
  };

  return (
    <ClientOnlyPortal selector="#modal-root">
      <div
        className={`fixed inset-0 overflow-y-auto ${
          state.displayed
            ? "pointer-events-auto z-[999]"
            : "pointer-events-none -z-50"
        }`}
        style={state.displayed ? zIndex : { zIndex: -50 }}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className={`fixed inset-0 transition-opacity ease-out duration-300 ${
              state.displayed
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            }`}
            aria-hidden={state.displayed}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className={`
            w-96 inline-flex flex-col flex-1 align-bottom ${
              bg ? bg : "bg-white"
            } rounded-lg px-4
              pt-5 pb-4 text-left overflow-hidden shadow-xl transform
              transition-all sm:align-middle
              ${cls()}
              ease-out duration-300
              ${
                state.displayed
                  ? "opacity-100 translate-y-0 sm:scale-100 pointer-events-auto"
                  : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 pointer-events-none"
              }
            `}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style={{
              maxHeight: !full ? "calc(100vh - 86px)" : "calc(100vh - 25px)",
            }}
          >
            <div className="relative z-30 grid grid-cols-12">
              <div className="col-span-10">
                <h2 className="text-xl font-semibold">
                  {title}
                  {subTitle && (
                    <span className="block font-medium text-sm text-gray-600 pt-1">
                      {subTitle}
                    </span>
                  )}
                </h2>
              </div>
              <div className="flex justify-end col-span-2">
                <button
                  className={`
                  text-gray-600 hover:text-gray-700 rounded-full outline-none
                  focus:outline-none focus:rounded-md focus:ring-2
                  focus:ring-offset-2 focus:ring-purple-500 grid justify-items-end
                `}
                  onClick={() => close()}
                >
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </ClientOnlyPortal>
  );
}
